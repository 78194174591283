.order-page {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.menu-list {
  width: 49%;
}

// .order-summary-section {
//   width: 50%;
//   padding: 0px 20px 20px 20px;
// }

.menu-item {
  display: flex;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
}

.menu-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.menu-item-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  justify-content: space-between;
}

.menu-item-text {
  display: flex;
  flex-direction: column;
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-controls button {
  margin: 0 10px;
}

.order-summary {
  border-radius: 10px;
  padding: 0px 20px 20px 20px;
  background-color: #ffffff;
}

.order-item {
  display: flex;
  margin-bottom: 10px;
}

.order-item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.order-item-details {
  margin-left: 10px;
}

.order-now-button {
  background-color: #42b677;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.order-now-button:hover {
  background-color: #42b677;
}

// Menu flow

.order-icons {
  width: 22px;
  height: 22px;
  color: white;
  background-color: #42b677;
  border-radius: 20px;
}

.order-price {
  color: #000000;
  font-weight: 600;
  font-size: medium;
}

.order-name {
  color: #42b677;
  font-size: x-large;
}

.order-description {
  color: #555555;
  font-size: medium;
}

.order-price-view {
  background-color: #ffc229;
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
}

// Summary flow

.food-summary {
  background-color: rgb(226, 242, 220);
  padding: 10px;
  border-radius: 10px;
}

.order-total {
  margin-top: 40px;
  margin-bottom: 40px;
}

.order-bill {
  padding-right: 8px;
}

/* General Layout */
.order-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #f7f7f7;
}

/* Sidebar (Categories Section) */
.categories-section {
  width: 15%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.categories {
  display: flex;
  flex-direction: column;
  // gap: 10px;
}

.category-card {
  padding: 15px;
  // border: 1px solid #e0e0e0;
  // border-radius: 5px;
  cursor: pointer;
  text-align: center;
  // background-color: #f9f9f9;
  // transition: background-color 0.2s ease;
}

.category-card.selected {
  background-color: #e2f2dc;
  color: #42b677;
  border-radius: 10px;
}

.category-card:hover {
  background-color: #f0f0f0;
}

/* Center Section (Food Menu) */
.menu-section {
  background-color: white;
  width: 45%;
  padding: 20px;
  overflow-y: auto;
  border-radius: 10px;
  margin: 0px 10px 0px 10px;
}

.food-menu {
  display: flex;
  flex-direction: column;
}

.filter-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-veg-buttons {
  padding: 10px 20px;
  border: 1px solid #42b677;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-veg-buttons button.active,
.filter-veg-buttons button:hover {
  background-color: #e2f2dc;
  color: #42b677;
}

.filter-non-buttons {
  padding: 10px 20px;
  border: 1px solid #42b677;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-non-buttons button.active,
.filter-non-buttons button:hover {
  background-color: #e2f2dc;
  color: #42b677;
}

.food-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 500px; /* Set max height for scrolling */
  overflow-y: auto; /* Enable scrolling */
}

.food-card {
  display: flex;
  flex-direction: row;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.food-image {
  display: flex;
  flex-direction: column;
}

.food-details {
  display: flex;
  flex-direction: column;
}

.food-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}

.food-direction {
  margin-left: 20px;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.food-price span {
  font-weight: bold;
  margin-bottom: 5px;
}

.food-price button {
  padding: 5px 10px 0px 10px;
  background-color: #42b677;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}

.food-price button:hover {
  background-color: #42b677;
}

/* Right Section (Order Summary) */
.order-summary-section {
  width: 35%;
  background-color: #ffffff;
  padding: 20px;
  overflow-y: auto;
  border-radius: 10px;
}

.order-summary {
  width: 100%;
}

.order-summary h3 {
  text-align: center;
  margin-bottom: 20px;
}

.order-summary table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.order-summary th {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}
.order-summary td {
  // padding: 10px;
  // padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

// .order-summary button {
//   padding: 5px 10px;
//   background-color: #4caf50;
//   color: white;
//   border: none;
//   cursor: pointer;
//   border-radius: 3px;
//   margin: 0 3px;
// }

// .order-summary button:hover {
//   background-color: #45a049;
// }

.summary-totals p {
  font-weight: bold;
  margin-bottom: 10px;
}

.order-now-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.order-now-button:hover {
  background-color: #e53935;
}
// @media (max-width: 768px) {
//   .booking-container {
//     flex-direction: column;
//   }

//   .categories-section,
//   .menu-section,
//   .order-summary-section {
//     width: 100%;
//     padding: 10px;
//   }

//   .categories-section {
//     display: flex;
//     flex-direction: row;
//     overflow-x: auto;
//   }

//   .category-card {
//     min-width: 100px;
//     flex: 1;
//     margin-right: 10px;
//   }
// }

.total-order-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 10px 20px 10px;
}

.icon-margin {
  margin: 5px;
}

.order-remove-icon {
  background-color: #d51504;
  color: white;
}

// .order-border-flow {
//   padding  : 20px;
//   background-color: white;
//   margin: 20px;
//   border-radius: 20px;
// }

.food-type-logo {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.food-listview-image {
  width: 140px;
  height: 100px;
  border-radius: 10px;
}

.custom-food-details-body {
  // height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-sub-label {
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.complimentary-section {
  background-color: white;
  padding: 20px;
  overflow-y: auto;
  border-radius: 10px;
  margin: 0px 40px 0px 40px;
}

// Complimentary flow

.complimentary-header {
  color: #000000;
  font-size: larger;
  font-weight: 500;
}

.complimentary-sub-header {
  color: #000000;
  font-size: large;
  font-weight: 500;
}

.complimentary-text {
  color: #000000;
  font-size: medium;
  font-weight: 400;
}

.total-order-compliment-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.complimentary-section {
  background-color: white;
  width: 50%;
  padding: 20px;
  overflow-y: auto;
  border-radius: 10px;
  margin: 0px 10px 0px 10px;
}

.aligh-complimentary {
  position: relative;
  width: 100%;
}