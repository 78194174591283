/* .body{
  background-color: rgb(255, 255, 255);
} */
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
}
.detail-content {
  padding-top: 20px;
}
.radio-group {
  display: flex;
}
.space {
  padding: 10px;
}
.form-section,
.selected-room,
.personal-details,
.bill-details {
  flex: 1 1 300px;
  // padding: 20px;
  /* padding: 20px; */
  /* border: 1px solid #000000; */
  background-color: white;
  border-radius: 20px;
}
.header-set {
  flex: 1 1 300px;
  padding: 20px;
  /* padding: 20px; */
}
.header-set h3 {
  padding-left: 20px;
}

.date-picker,
.dropdowns {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.date-picker > .k-datepicker,
.dropdowns > .k-dropdown {
  flex: 1 1 45%;
}

.selected-room img {
  width: 100%;
  height: auto;
  /* border-radius: 8px;  Already curved*/
}

input[type="file"] {
  display: block;
  margin-top: 10px;
}

.bill-details p,
.bill-details h4 {
  margin: 10px 0;
}
.select-booking {
  display: flex;
  justify-content: row;
  // flex-wrap: wrap;
  // padding-top: 25px;
  // padding-left: 25px;
  // padding: "25px";
  align-items: center;
  justify-content: start;
}
.image-spaces {
  padding: 10px 0px 10px 0px;
  margin-left: 25px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .date-picker,
  .dropdowns {
    flex-direction: column;
  }
  .select-booking {
    display: flex;
    justify-content: row;
    flex-wrap: wrap;
    padding-top: 25px;
    align-items: center;
    justify-content: center;
  }
  .image-spaces {
    margin-left: 0px;
  }

  .date-picker > .k-datepicker,
  .dropdowns > .k-dropdown {
    flex: 1 1 100%;
  }
}
.booking-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;

  /* justify-content: space-around; */
}

.form-group {
  flex: 1 1 calc(50% - 20px);
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.form-row {
  background-color: white;
  border-radius: 20px;
  width: 48%;
  // height: 118px;
  justify-content: center;
  display: flex 2;
}

label {
  margin-bottom: 8px;
}

@media (max-width: 886px) {
  /* .form-group {
    flex: 1 1 100%;
  } */

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;

    gap: 20px;
    margin: 2%;
    // height: 120px;
    width: 100%;
    /* flex-wrap: row wrap; */
    /* justify-content: space-around; */
  }
}

.top-space {
  padding-top: 50px;
  background-color: #eef3f2;
}

/* Top filters */

.common-space {
  padding: 20px;
}

/* Select room */

.overall-title {
  margin-right: 10px;
}

.small-padding {
  padding: 4px;
}

.heading-padding {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 24px;
  padding-bottom: 32px;
}

.low-bottom-padding {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 32px;
}

.heading-size {
  font-size: 30px;
  font-weight: 500;
}

.sub-heading-size {
  font-size: 24px;
  font-weight: 400;
}

.description-size {
  font-size: 20px;
  font-weight: 500;
}

/* Fields label */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.field-label {
  font-size: 18px;
  font-weight: 400;
}

.error-field {
  color: #ff0000;
  font-size: 16px;
  font-weight: 400;
  padding-top: 10px;
}

.initial-field {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 16px;
}

.field-top {
  padding-top: 8px;
}

.remaining-field {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 16px;
}

.manditory-fields {
  margin-left: 5px;
  color: #ff0000;
}

.personal-fields {
  height: 42px;
  background-color: white;
  color: black;
  border: 1px solid #bebebe;
  border-radius: 4px;
  box-sizing: border-box;
}

.field-space {
  margin-top: 16px;
  margin-bottom: 16px;
}

/* Billing details */

.dash-line {
  width: 100%;
  border-top: 1px dashed #dcdcdc;
}

.summary-container {
  padding: 10px 20px;
  border-radius: 10px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.summary-text {
  margin: 0px 10px 10px 10px;
  font-size: 20px;
}

.summary-price {
  font-size: 20px;
}

.summary-dotted-line {
  border-bottom: 1px dashed #ccc;
  margin: 5px 0;
}

.total-color {
  margin: 0px 10px 10px 10px;
  color: #757575;
}

.cancel-heading {
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.button-style {
  width: 100%;
  padding: 15px;
  background-color: #42b677;
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 60px;
}
.button-style-cancel {
  width: 100%;
  padding: 15px;
  background-color: #ffffff;
  color: #ed823a;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 60px;
  border-color: #ed823a;
}
.button-style-outline {
  width: 100%;
  padding: 15px;
  background-color: #42b677;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 60px;
  border-color: #42b677;
}
.button-style-end {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  background-color: #42b677;
  margin-right: 10%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 60px;
  border-color: #42b677;
}
.button-style-outline-inprogress {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  background-color: #ed823a;
  margin-right: 10%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 60px;
  border-color: #ed823a;
  justify-content: center;
  // align-items: center;
  display: flex;
}
.button-style-outline-Canel {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  background-color: #ed3a3a;
  margin-right: 10%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 60px;
  border-color: #ed3a3a;
}
.button-style-outline-1 {
  margin-top: 20px;
  width: 45%;
  padding: 15px;
  border-radius: 60px;
  background-color: #ffffff;
  color: #42b677;
  font-size: 16px;
  border-color: #ffffff;
}
.button-view {
  margin: 40px 20px 20px 20px;
}

.spacing-card {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-row {
  padding: 14px;
  background-color: #42b677;
}

.top-view {
  margin-left: 24px;
  margin-right: 24px;
}

.top-normal {
  cursor: pointer;
  color: white;
  font-size: 18px;
}

.top-selected {
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-size: 18px;
}

.menu-alignment {
  align-self: flex-end;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh; /* Full height of the viewport */
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}

// dialog view
.full-dialog-row {
  background-color: white;
  border-radius: 20px;
  width: 100%;
  justify-content: center;
}

.alert-button {
  width: 100%;
  background-color: #42b677;
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 20px;
  margin-bottom: 20px;
}

.custom-link {
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-decoration: none; /* Remove underline if desired */
}

.custom-link:hover {
  color: white; /* Keep the color black on hover */
}
.back-icon {
  font-size: 30px;
  margin-left: 14px;
}

.valid-day-fields {
  margin-top: 10px;
  margin-left: 5px;
  color: #ff0000;
}
.pannelBar {
  border-width: 5;
  border-radius: 20px;
  border-style: solid;
  border-color: rgba(66, 182, 119, 255);
  background-color: "#f8f8f8";
  box-shadow: "0 0  20px rgba(66,182,119,255)";
}

// Srikkanth scss

.dialog-container {
  border-radius: 10px;
  padding: 50px;
  display: flex;
  position: fixed;
  // width: 500px;
  height: 100%; /* Increase the height here */
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%; /* Ensure the content takes up the full height */
  justify-content: center; /* Center the content vertically */
}
.dialog-container .k-dialog-titlebar {
  display: none;
}

.dialog-container .k-dialog {
  border-radius: 10px;
  padding: 20px;
  // width: 300px;
  // height: 130%;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  width: 100%;
  margin-bottom: 10px;
}

.input-control {
  width: 100%;
  height: 50px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.primary-button {
  border-radius: 20px;
}

.link-register {
  color: #3ab54a;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
}
.booking-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.bill-details-header {
  margin-top: 20px;
  font-size: 24px;
  margin-bottom: 20px;
}

.booking-details-card {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  width: 95%;
  padding: 20px;
}

.left-section {
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.left-section-removed {
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.right-section {
  // width: 40%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.right-section-coupon {
  // width: 40%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px 10px 20px;
  margin-bottom: 10px;
}

.space-details-header {
  display: flex;
  align-items: center;
  padding: 0;
}

.space-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
}

.space-details h3 {
  margin: 0;
  font-size: 20px;
}

.space-details p {
  margin: 5px 0 0;
  color: #888;
}

.space-info .info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.info-item h4 {
  margin: 0;
  font-size: 16px;
  color: #888;
}

.info-item p {
  margin: 0;
  font-size: 16px;
}

.edit-link {
  color: #3ab54a;
  cursor: pointer;
  font-size: 14px;
}

.summary-info {
  margin-bottom: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.summary-item.total {
  font-weight: bold;
}

.cancellation-policy h4 {
  margin: 20px 0 10px;
}

.cancellation-policy ul {
  margin: 0;
  padding-left: 20px;
  font-size: 14px;
  color: #888;
}

.confirm-button {
  width: 100%;
  padding: 15px;
  background-color: #3ab54a;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}
.ul-list {
  list-style-type: none;
  color: #757575;
  font-weight: 400;
}
.ul-list-view {
  font: family/family;
  color: #000000;
  font-weight: 500;
}
.no-scroll {
  overflow: hidden;
}

.description-text {
  font-size: 18px;
  font-weight: 500;
}

// Price design

.containerStyle {
  width: 90%;
  background-color: #fffaf0; // Light yellow background
  border: 1px solid #ff4500; // Orange border
  border-radius: 20px;
  padding: 16px 24px 16px 24px;
  display: inline-block;
  margin-left: 20px;
}

.priceStyle {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}

.baseRateStyle {
  font-size: 12px;
  color: #808080;
  margin-left: 10px;
}

.k-scheduler-slot-disabled {
  background-color: #f5f5f5; /* Light gray background for past slots */
  pointer-events: none; /* Disable interaction */
  opacity: 0.6; /* Make it visually distinct */
}

.k-scheduler-slot-active {
  background-color: #fff; /* Normal background for active future slots */
  pointer-events: auto; /* Enable interaction */
}
k-datepicker-prev {
  display: none;
}
k-datepicker-next {
  display: none;
}

.dialog-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center; /* Center the content vertically */
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 24px;
  padding-bottom: 20px;
}

.login-button-style {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  padding: 8px;
  background-color: #42b677;
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 60px;
}

// Monthly colors

.white-color {
  background-color: white;
}

.red-color {
  background-color: #fddad6;
}

.yellow-color {
  background-color: #fef7b5;
}

.green-color {
  background-color: #bbffbc;
}

.login-button-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  padding: 8px;
}

.login-button-dialog {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 40%;
  padding: 8px;
  background-color: #42b677;
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 60px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Adjust this as needed */
}

.dialog-heading {
  flex-grow: 1;
  text-align: center;
}

.svg-aligh {
  border-color: rgba(0, 0, 0, 0);
  background-color: white;
  align-self: flex-start;
}

.hourly-header-label {
  width: 100%;
  background-color: white;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.custom-timepicker input {
  // pointer-events: none;  /* Disable user interaction */
  user-select: none; /* Prevent selecting text */
  -webkit-appearance: none; /* Disable default input styling */
  -moz-appearance: none;
  appearance: none;
}

.custom-timepicker input::-webkit-inner-spin-button,
.custom-timepicker input::-webkit-calendar-picker-indicator {
  display: none; /* Hide the spinner (up/down arrow) */
}

.custom-timepicker input::-moz-inner-spin-button {
  display: none; /* Hide the spinner for Firefox */
}

.popup-button-block {
  display: block;
  margin-bottom: 10px;
}

.popup-button-color {
  color: #3ab54a;
}

// My Bookings

.booking-container {
  background-color: #eef3f2;
}

.bookings-header {
  margin-top: 20px;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

/* App.css */
.sort-filter-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.custom-dropdown {
  border: 1px solid #ccc; /* Light border */
  border-radius: 20px; /* Rounded corners */
  padding: 5px 15px; /* Padding inside the button */
  font-size: 16px;
  color: #333; /* Text color */
  background-color: white;
  cursor: pointer;
  width: 180px;
  // min-width: 100px; /* Adjust based on content */
}

.custom-dropdown .k-dropdown-wrap {
  border: none; /* Remove the default border */
}

.custom-dropdown .k-input {
  padding: 5px 0px; /* Adjust padding inside */
}

.custom-dropdown:hover {
  border: 1px solid #28a745; /* Change border color on hover */
}

.custom-dropdown .k-icon {
  margin-left: auto;
}

.custom-dropdown .k-dropdown-button {
  border-radius: 20px; /* Same rounded style for the dropdown button */
  padding: 5px; /* Smaller padding */
}

.custom-dropdown.k-state-focused {
  box-shadow: none; /* Remove any focus outline */
}

.booking-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%; /* Full width of the container */
}

.booking-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  min-width: 100%;
  background-color: #fff;
}

.booking-image img {
  width: 120px;
  height: 120px;
  border-radius: 15px;
  object-fit: cover;
}

.booking-details {
  flex: 1;
  margin-left: 20px;
}

.booking-details h3 {
  margin: 0;
  font-size: 18px;
}

.booking-status {
  display: inline-block;
  padding: 5px 15px;
  color: white;
  border-radius: 12px;
  font-size: 14px;
  margin-top: 5px;
}

.booking-info {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.5;
}

.booking-action {
  margin-left: 20px;
}

button.k-button.k-primary {
  border-radius: 20px;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
}

/* Full-width responsive adjustments */
@media (max-width: 768px) {
  .booking-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .booking-action {
    margin-left: 0;
    margin-top: 10px;
  }
}

// My Booking Details

.paymentTag {
  margin-bottom: 10px;
}

.partiallyPaid {
  padding: 6px 20px;
  background-color: #fff4df;
  color: #333;
  border-radius: 20px;
  border: 1px solid #ffd700;
}

// Listview inside My Bookings

.listview-container {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  margin: 0%;
  height: 128px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
}
.listview-imageContainer {
  flex-shrink: 0;
  margin-right: 15px;
}
.listview-image {
  width: 150px;
  height: 100px;
  border-radius: 10px;
}
.listview-detailsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.listview-detailsContainer-1 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.listview-firstRow {
  display: flex;
  flex-grow: 4;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  justify-content: space-between;
}
.listview-secondRow {
  display: flex;
  align-items: center;
  color: #6b6b6b;
  font-size: 14px;
  justify-content: space-between;
}
.listview-row {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.listview-title {
  font-size: 18px;
  font-weight: bold;
  margin-right: auto;
}
.listview-detail {
  margin-right: 75px;
}
.listview-detail-1 {
  margin-left: 20px;
  margin-right: 20px;
  // display: flex;
}
.listview-detail-2 {
  margin-left: 20px;
  // display: flex;
}
.listview-detail-3 {
  margin-left: 20px;
  // display: flex;
}
.listview-title_label {
  color: #8a8a8a;
  font-weight: bold;
  margin-right: 5px;
}
.listview-value {
  color: #333;
}
.listview-status {
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
.listview-buttonContainer {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}
.listview-button {
  background-color: #27ae60;
  color: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 4px;
}

// Card demo
.custom-booking-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-image-container {
  flex-shrink: 0;
  margin-right: 20px;
}

.custom-booking-image {
  border-radius: 10px;
  width: 100px;
  height: 80px;
  object-fit: cover;
}

.custom-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.custom-details-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.custom-room-title {
  font-size: 18px;
  font-weight: bold;
  color: #424242;
  margin-bottom: 5px; /* Adds some space between the title and status */
}

.custom-status-badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 14px;
  width: fit-content; /* Makes the badge fit its content */
}

.custom-details-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-detail-item {
  display: flex;
  flex-direction: column;
}

.custom-label {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
}

.custom-value {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.custom-button-container {
  margin-left: 20px;
}

.custom-details-button {
  white-space: nowrap;
}

.custom-no-data {
  margin-top: 10px;
  color: #ff5252;
}

// booking details demo

.booking-details-container {
  // border: 1px solid #ddd;
  // border-radius: 8px;
  // padding: 20px;
  // max-width: 400px;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.date-section {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-box {
  display: flex;
  flex-direction: column;
  // width: 48%;
}

.date-box label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #000000;
  font-weight: 500;
}

.date-box-value-label {
  font-size: 16px;
  // margin-bottom: 8px;
  color: #000000;
  font-weight: 700;
}

.edit-date {
  display: flex;
  align-items: center;
}

.edit-link {
  font-size: 12px;
  color: green;
  margin-left: 8px;
  text-decoration: none;
  font-weight: bold;
}

.edit-link:hover {
  text-decoration: underline;
}

.price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.price-label {
  font-size: 16px;
  color: #666;
}

.price-value {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
.backbutton {
  width: fit-content;
  height: fit-content;
  color: #42b677;
}
.back-text {
  color: #42b677;
}
.kendo-back-icon {
  color: #42b677;
}

.my-booking-container {
  border-radius: 10px;
}

.booking-history-container {
  display: flex;
  flex-direction: row;
}

.booking-left-section-removed {
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.booking-right-section {
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  padding: 20px;
}

.booking-details-header {
  display: flex;
  align-items: center;
  padding: 0;
}

.custom-status-badge {
  margin-top: 5px;
  padding: 5px 15px 5px 15px;
  border-radius: 12px;
  font-size: 14px;
  width: fit-content; /* Makes the badge fit its content */
}

.mybooking-details-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  width: 95%;
  padding: 20px;
}
.field-label-total-color-price {
  color: #212121;
  font-weight: 500;
  font-size: x-large;
}
.wrapper-grid {
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-gap: 1rem;
}
// .viewmore-dialog {
//   background-color: white;
//   backdrop-filter: blur(5px);
// }
.booking-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

.coupan-apply {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  height: fit-content;
  color: #42b677;
}

.discount-container {
  display: flex;
  flex-direction: column;
}

.discount-padding {
  padding: 10px;
  margin: 0px 10px 0px 10px;
}
.summary-container-1 {
  padding: 10px 20px;
  border-radius: 10px;
  width: 40%;
}
.k-card right-section k-card-vertical-coupon {
  height: fit-content;
}
.back-text {
  font-size: medium;
}
.apply-coupon-text {
  font-size: x-large;
  color: #42b677;
}
.kendo-coupon-icon {
  color: #42b677;
}

.discount-coupan-text {
  color: #42b677;
  font-weight: bold;
  text-transform: uppercase;
}

.coupon-applied-text {
  display: flex;
  justify-content: space-between;
  font-size: large;
  color: #42b677;
  margin-bottom: 0px;
  // text-transform: uppercase;
}
.manditory-fields-coupon {
  font-size: medium;
  margin-left: 5px;
  color: #ff0000;
}
.coupon-applied-text-1 {
  // display: flex;
  // justify-content: space-between;
  font-size: medium;
  color: #757575;
  // margin-bottom: 0px;
  text-transform: uppercase;
}
.coupon-applied-text-2 {
  // display: flex;
  // justify-content: space-between;
  font-size: small;
  color: #757575;
  // margin-bottom: 0px;
  // text-transform: uppercase;
}

.order-listview-container {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  margin: 0%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  margin-bottom: 20px;
}

.order-detail-item {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.space-top-only {
  padding-left: 8px;
  padding-top: 12px;
}

.button-style-outline-2 {
  margin-top: 20px;
  width: 45%;
  padding: 15px;
  border-radius: 60px;
  background-color: #42b677;
  color: white;
  font-size: 16px;
  border-color: #42b677;
}

.policy_title {
    font-size: 16px;
    color: black;
    text-decoration: "underline";
    font-weight: 500;
  
}

// Enter Coupon

.coupon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.button-section {
  white-space: nowrap;
}

.input-section {
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  gap: 0.5rem; /* Adjust for spacing between input and button */
  align-items: center;
  width: 100%;
}

button {
  white-space: nowrap;
}